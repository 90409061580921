// CSS Style
import 'admin/styles/site.scss'
import 'admin/styles/style.min.css'
import 'admin/styles/custom.scss'

// Other JS
import('admin/site')
import 'admin/notification'

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
// import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// 因為在 webpack 打包的時候，沒有瀏覽器的 window 物件，所以必須 assign 這樣才能在 rails view 使用
// Ref: https://kaochenlong.com/2019/11/22/webpacker-with-rails-part-2/
window.jQuery = $;
window.$ = $;
